import { createGlobalStyle } from 'styled-components';

import { cyan, grey, blue, green, red } from '@mui/material/colors';

export const COLOR_PRIMARY_CONTRAST = cyan[50]; //#e0f7fa
export const COLOR_PRIMARY_LIGHT = cyan[200]; //#80deea
export const COLOR_PRIMARY = cyan[500]; //#00bcd4
export const COLOR_ACCENT = cyan[600]; //#00acc1
export const COLOR_PRIMARY_DARK = cyan[800]; //#00838f
export const COLOR_PRIMARY_DARKER = cyan[900]; //#006064

export const COLOR_GREY_SUPER_LIGHT = grey[100]; //#f5f5f5
export const COLOR_PAGE_BACKGROUND = grey[100]; //#f5f5f5
export const COLOR_GREY_LIGHT = grey[300]; //#e0e0e0
export const COLOR_GREY_LIGHTER = grey[500]; //#9e9e9e
export const COLOR_GREY = grey[600]; //#757575
export const COLOR_GREY_DARK = grey[700]; //#616161
export const COLOR_GREY_DARKER = grey[800]; //#424242

export const COLOR_ERROR_LIGHT = red[200]; //#ffab91
export const COLOR_ERROR = red[700]; //#e64a19

export const COLOR_CHECKED = green[500]; //#4caf50

export const COLOR_BLUE = blue.A100;
export const COLOR_WHITE = '#ffffff';
export const COLOR_BLACK = '#000000';
export const COLOR_TEXT = '#000000';

export const SM = 768;

export const LG = 1200;

const MOBILE_BREAKPOINT = 1200;
const MD = 992;

export const MEDIA_QUERIES_MOBILE = `max-width: ${MOBILE_BREAKPOINT}px`;
export const DIALOG_BREAKPOINT = 'max-width:960px';
export const HEADER_BREAKPOINT = `max-width:${MOBILE_BREAKPOINT}px`;
export const MEDIA_QUERIES_XS = 'max-width: 767px';
export const MEDIA_QUERIES_MD = `max-width: ${MD}px`;

export const MEDIA_CREATE_ACTIONS = 'max-width: 1150px';

export const OPENED_NAV_WIDTH = 272;
export const CLOSED_NAV_WIDTH = 56;
export const APP_BAR_HEIGHT = 64;

export const GlobalStyle = createGlobalStyle`
  
  .clickable {
    cursor: pointer;
  }

  .cursor-default {
    cursor: default;
  }

  .cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .heatmap-tooltip {
    position: absolute;
    pointer-events: none;
    padding: 5px;
    font-size: 10px;
    line-height: 12px;
    box-shadow: 1px 1px 0 rgba(0,0,0,.2);
    background: #fff;
    border-radius: 2px;
    z-index: 9999;
  }

  .heatmap-plot rect:hover {
    opacity: .9;
  }

  .default-btn-bcg {
    background-color: ${COLOR_WHITE} !important;
  }

  .disabled-btn {
    background-color: ${COLOR_GREY_LIGHT} !important;
    color: ${COLOR_GREY} !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
  }





  .assign-owner-dialog {
    .dialog-title {
      background-color: ${COLOR_PRIMARY};

      h2 {
        color: ${COLOR_WHITE};
      }
    }

    .dialog-content {
      padding: 20px;
    }

    & > div:last-child {
      max-width: 100%;
    }

    .actions-element {
      padding: 0 15px 10px;

      button {
        background-color: ${COLOR_WHITE};
        margin-left: 15px;
      }
    }
  }

  #menu-search-by {
    & > div:last-child {
      border: 2px solid ${COLOR_BLUE};
    }
  }






  .add-user-dialog {
    & > div:last-child {
      max-width: 750px;
      width: 100%;
      border: 2px solid ${COLOR_PRIMARY};

      .title {
        background-color: ${COLOR_PRIMARY};

        h2 {
          color: ${COLOR_WHITE};
          font-size: 1.2rem;
        }

        .close-icon {
          color: ${COLOR_WHITE};
        }
      }

      .dialog-content {
        padding: 20px;

        .add-user-title {
          font-size: 1.2rem;
          display: flex;
          margin-bottom: 20px;

          .question-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            margin-left: 10px;
            background-color: ${COLOR_BLUE};
            color: white;
            border-radius: 20px;
            font-size: 0.7rem;
          }
        }

        .add-user-content {
          display: flex;
          justify-content: space-between;

          .users-list-container {
            flex-grow: 1;

            .users-list-head {
              display: flex;

              .users-list-item {
                height: 42px;
                display: flex;
                align-items: center;
                font-size: 1.2rem;
                padding-left: 10px;
                margin-bottom: 0;

                &.email {
                  flex-grow: 1;
                }

                &.permission {
                  flex-basis: 30%;
                      padding-left: 30px;
                }

                &.remove-pane {
                  flex-basis: 10%;
                }
              }
            }

            .users-list-content {
              max-height: 150px;
              overflow-y: auto;
              border: 1px solid  ${COLOR_GREY_LIGHT};
              border-right: 0;
              border-left: 0;
              padding-top: 15px;
              margin-bottom: 10px;

              .users-list-item {
                display: flex;
                margin-bottom: 15px;

                &.error {
                  .users-list-item-column {
                    &.email {
                      & > div {
                        input {
                          border-color: ${COLOR_ERROR};
                        }
                      }
                    }
                  }
                }

                .users-list-item-column {
                  &.email {
                    flex-grow: 1;

                    & > div {
                      width: 100%;

                      input {
                        border: 2px solid ${COLOR_PRIMARY};
                      }
                    }
                  }

                  &.permission {
                    flex-basis: 30%;
                    margin-left: 10px;
                    margin-right: 4px;

                    .form-item {
                      width: auto;

                      .select {
                        border: 2px solid ${COLOR_PRIMARY};
                        height: 38px;
                        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);

                        & > div {
                          & > div {
                            display: flex;
                            align-items: center;
                            padding: 0 32px 0 10px;
                            height: 100%;
                          }
                        }
                      }

                      .select:after {
                        height: 0;
                      }

                      .select:before {
                        height: 0;
                      }
                    }
                  }

                  &.remove-action {
                    display: flex;
                    flex-basis: 10%;

                    button {
                      margin: auto;
                      width: auto;
                      height: auto;
                      background-color: white;
                      min-height: 0;
                      padding: 3px;
                      box-shadow: none;

                      &:hover {
                        background-color: ${COLOR_GREY_LIGHT};
                      }
                    }
                  }
                }
              }

              .error-alert {
                color: ${COLOR_ERROR};
                margin-left: 10px;
              }
            }

            .add-user-btn-container {
              button {
                width: auto;
                height: auto;
              }

              .add-user-btn {
                background: ${COLOR_CHECKED};
                border-radius: 50%;
                display: inline-flex;
                padding: 6px;
                color: ${COLOR_WHITE};
                cursor: pointer;
                height: 24px;
                width: 24px;
              }
            }
          }

          .invite-action-container {
            padding: 42px 20px 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .status-loading,
            .status-error,
            button {
              height: 42px;
              display: flex;
              align-items: center;
              margin-bottom: 15px;
            }

            .status-loading {
              color: ${COLOR_CHECKED};
              text-transform: uppercase;
            }

            button {
              background-color: ${COLOR_PRIMARY};
              color: ${COLOR_WHITE};
            }

            .status-error {
              color: ${COLOR_ERROR};
            }
          }
        }
      }

      .actions {
        padding: 10px;

        button {
          box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
          min-width: 120px;

          &.cancel-btn {
            background-color: white;
          }
        }

        button:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  @media (${MEDIA_QUERIES_XS}) {
    .add-user-dialog {
      & > div:last-child {
        margin: 0;
        max-height: 100vh;
        height: 99%;

        .dialog-content {
          .add-user-content {
            flex-direction: column-reverse;

            .invite-action-container {
              padding-top: 0;
            }
          }
        }

        & > div:last-child {
          & > div {
            flex-basis: 100%;

            & > div {
              display: flex;

              button {
                flex-basis: 50%;
                background-color: ${COLOR_WHITE};

                &.submit {
                  background-color: ${COLOR_PRIMARY};
                }
              }
            }
          }
        }
      }
    }
  }




  #menu-colors li .color-value {
    width: 20px;
    height: 20px;
  }

  .circle-picker {
    margin: 0 !important;
    padding: 14px 0 0 14px;
  }

  .change-image {
    .providers-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &.browse-image {
        button {
          height: 46px;
          width: 100%;
          padding: 0;

          span:first-child {
            height: 46px;

            label {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }
        }
      }

      img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        border-radius: 50%;
      }

      button {
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      }

      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
    }
  }





  .edit-image > div:last-child {
    min-width: 0;
    width: 300px;

    .dialog-title h2 {
      font-size: 15px;
      text-align: center;
    }

    .dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > canvas {
        margin-bottom: 15px;
      }

      & > button {
        margin-bottom: 20px;
      }
    }

    .dialog-actions {
      width: 100%;
      display: flex;

      .save-btn {
        margin-right: 10px;
      }
    }
  }




  .email-domains-confirmation {
    & > div:last-child {
      max-width: 550px;
      width: 100%;
      border: 2px solid ${COLOR_PRIMARY};

      .title {
        background-color: ${COLOR_PRIMARY};

        h2 {
          color: ${COLOR_WHITE};
          font-size: 1.2rem;
        }

        .close-icon {
          color: ${COLOR_WHITE};
        }
      }

      .dialog-content {
        padding: 20px;
      }
    }
  }





  #action-filter {
    li.sub-category {
      padding-left: 35px;
    }
  }

  #download-menu {
    .download-processed-file {
      text-decoration: none;
      color: ${COLOR_BLACK};
    }
  }

  .download-actions-popover {
    .popover-item {
      display: block;
      padding: 10px;
      cursor: pointer;
      text-decoration: none;
      color: inherit;

      &:hover {
        background-color: ${COLOR_GREY_SUPER_LIGHT};
      }

      .size {
        margin-left: 5px;
      }
    }
  }

  .info-popover {
    & > div {
      padding: 10px 10px 0;

      .field {
        margin-bottom: 10px;

        .title {
          font-weight: bold;
          text-transform: capitalize;
          margin-right: 5px;
        }
      }
    }
  }




  .owner-field-popover {
    .owner-field-popover-content {
      &.loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 174px;
        height: 97px;
      }

      padding: 10px;
      font-size: 14px;
      background-color: ${COLOR_WHITE};
      color: ${COLOR_TEXT};
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

      .fullname,
      .user-id,
      .university,
      .email,
      .permission {
        margin-bottom: 3px;

        .label {
          text-transform: capitalize;
          margin-right: 3px;
        }
      }

      .fullname {
        font-size: 16px;
      }

      .user-id {
        color: ${COLOR_GREY};
      }

      .permission {
        .value {
          margin-left: 3px;
        }
      }
    }
  }




  #qr-code-dialog {
    #qr-code h2 {
      text-align: center;
    }

    .dialog-content {
      .actions {
        display: flex;
        justify-content: flex-end;

        & > *:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }





  .member-permission-change-confirmation {
    .dialog-actions {
      .submit-action, .cancel-action {
        width: 90px;
      }
    }
  }






  .change-email-dialog {
    & > div:last-child {
      margin: 0;
    }

    h2 {
      font-weight: bold;
      text-align: center;
    }

    .dialog-content {
      padding-bottom: 0;

      .change-email-content {
        padding: 0px;
        width: 100%;
        max-width: 300px;

        .error-alert {
          color: ${COLOR_ERROR};
          margin-bottom: 10px;
        }

        .form-control {
          width: 100%;
          margin-bottom: 10px;

          & > label {
            font-size: 24px;
            color: ${COLOR_TEXT};
          }

          .form-item {
            width: 100%;
            margin-top: 30px;

            input {
              border: 2px solid ${COLOR_PRIMARY};
            }

            .password-input {
              margin-top: 10px;
            }
          }
        }
      }
    }

    .change-email-actions {
      padding: 0 20px 20px;

      .cancel {
        margin-right: 10px;
      }
    }
  }





  .change-password-dialog {
    & > div:last-child {
      margin: 0;
    }

    h2 {
      font-weight: bold;
      text-align: center;
    }

    .change-password-content {
      padding: 0px;
      width: 100%;
      max-width: 300px;

      .error-alert {
        color: ${COLOR_ERROR};
        margin-bottom: 20px;
        text-align: center;
      }

      .form-control {
        width: 100%;
        margin-bottom: 10px;

        & > label {
          font-size: 24px;
          color: ${COLOR_TEXT};
        }

        .form-item {
          width: 100%;
          margin-top: 30px;

          input {
            border: 2px solid ${COLOR_PRIMARY};
          }

          .password-input {
            margin-top: 10px;
          }
        }
      }

      .password-rules-block {
        padding: 15px;

        .pass-rule-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          &.error {
            color: ${COLOR_ERROR_LIGHT};

            & > svg {
              background-color: ${COLOR_ERROR_LIGHT};
            }
          }

          &.checked {
            color: ${COLOR_CHECKED};

            & > svg {
              background-color: ${COLOR_CHECKED};
            }
          }

          & > svg {
            color: ${COLOR_WHITE};
            margin-right: 10px;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            color: white;
          }
        }
      }
    }

    .change-password-actions {
      padding: 0 20px 20px;

      .cancel {
        margin-right: 10px;
      }
    }
  }






  #menu-colors li .color-value {
    width: 20px;
    height: 20px;
  }

  .circle-picker {
    margin: 0 !important;
    padding: 14px 0 0 14px;
  }

  .change-image {
    .providers-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &.browse-image {
        button {
          height: 46px;
          width: 100%;
          padding: 0;

          span:first-child {
            height: 46px;

            label {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }
        }
      }

      img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        border-radius: 50%;
      }

      button {
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      }

      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
    }
  }





  #app-menu ul > li {
    text-transform: capitalize;
  }



  #menu-visibility {
    ul {
      padding: 0;

      li {
        display: flex;
        justify-content: space-between;
        padding: 10px;
      }
    }
  }





  .upload-files-modal {
    .file {
      display: flex;
      justify-content: space-between;
      width: 270px;
      padding: 10px;
      align-items: center;
      border: 1px solid ${COLOR_GREY_LIGHT};
      border-radius: 5px;
      margin-bottom: 10px;
      align-items: flex-start;

      &:hover {
        background-color: ${COLOR_GREY_LIGHT};
      }

      .close-icon {
        cursor: pointer;
      }
    }

    .actions {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      padding: 10px;
    }
  }
`;
